<template>
  <div>
    <topmenu :comname="'mediaFocus'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>媒体聚焦</span>
      </div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <div v-if="isshowlist" class="listinfor" v-loading="loading">
          <ul>
            <li v-for="item in list" :key="item.id">
              <div  class="fl cur">
                 <a :href="item.linkUrl" target="blank">
                <el-image
                  :src="item.imgUrl?item.imgUrl:require('@/assets/images/dtmr.jpg')" :alt="item.imgUrl?item.title:''"
                  fit="contain"
                  :title="item.title"
                  style="width:172px; height:104px;"
                ></el-image>
                </a>
              </div>
              <div class="contm">
               
                <a :href="item.linkUrl" target="blank">
                  <span class="tlew" :title="item.title">[媒体名称] 
                    {{item.title.length>40?comJs.limitNum(item.title,40):item.title}}
                    </span>
                </a>
             <div class="lun">
                 <a :href="item.linkUrl" target="blank">
                  {{item.guide!=null&&item.guide.length>80?comJs.limitNum(item.guide,80):item.guide}}
                
                    <span class="red">[查看更多]</span>
                  </a>
                </div>
                <div class="time">{{item.addTime | formatDate}}</div> 
              </div>
            </li>
          </ul>
          <el-pagination
            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-count="totalPage"
            :current-page="currentPage"
            :page-size="pagesize"
            :hide-on-single-page="true"
            class="t_page"
          ></el-pagination>
        </div>
        <nocont v-else></nocont>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "mediaFocus",
  data() {
    return {
      list:[],
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize:10, //每页显示条数
      totalPage: 0, //总页数
      data:"",
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont
  },
  inject:['reload'],
  created() {
    this.getData();
  },
  watch: {
    //监听翻页
    currentPage: function(newVal, oldVal) {
      this.loading = true;
      this.getData();
    }
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    }
  },
  methods: {
    handleSizeChange: function(size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
     // 页面跳转判断
    jumpDteails(record){
      if(!record.linkUrl){
        this.$router.push({
          path: '/mediaFocus',
          query: {
            id: record.id
          }
        })
      }else{
        window.open(record.linkUrl)
        // window.location.href = record.webUrl
      }
    },
    getData() {
      let data = {
        current: this.currentPage,
        size: this.pagesize
      };
      this.$axios.post("/api/app/mediaFocus/getMediaFocus", data)
        .then(res => {
    
          this.loading = false;
          this.totalPage = res[1].pages;
          let that=this
          debugger       
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(res[0]);
           this.list=res[0]
       
            console.log("媒体聚焦",this.list,typeof(this.list))
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
        });
    }
  }
};
</script>
<style scoped>
.listinfor {
  padding: 40px 0;
}
.listinfor ul {
  display: block;
  overflow: hidden;
  padding: 0 25px;
}
.listinfor li {
  display: block;
  background: #fafafa;
  padding: 15px 10px;
  margin-bottom: 15px;
  overflow: hidden;
}
.listinfor li:hover {
  background: #f2f7fe;
}
.listinfor li a:hover .tlew,.listinfor li:hover .tlew{color:#4467CF}
.listinfor li a{
  cursor: pointer!important;
}
.t_page {
  margin-top: 30px;
}
.contm {
  text-align: left;
  padding: 0 5px 0 15px;
  float: left;
  width: 768px;
}
.lun {
  line-height: 22px;
  padding: 10px 0;
  color: #666;
  height: 44px;
   text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tlew {
  font-size: 18px;
  line-height: 22px;
  color: #333;
  width: 100%;
  display: inline-block;white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.time {
  font-size: 15px;
  color: #999;
}
</style>